import React, { useState, useEffect, useContext } from "react";
import {
  Routes,
  Route,
  Link,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom"; // Removed the redundant import for Router npm run build
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ImageSlider from "./components/component/ImageSlider";
import Search from "./components/component/Search";
import SignInPage from "./components/Signin";
import { AuthContext } from "./hook/authContext";
import NotFoundPage from "./components/NotFoundPage";
import VideoSliderAds from "./components/AdScripts/VideoSliderAds";
import YandexMetrika from "./components/Schema/YandexMetrika";
import {
  faSearch,
  faUser,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import HeaderMenu from "./components/component/HeaderMenu";
import Home from "./components/Home";
import Videos from "./components/Videos";
import Dashboard from "./components/dashboard/Dashboard";
import VideoPlayer from "./components/VideoPlayer";
import ImagesView from "./components/ImagesView";
import Images from "./components/Images";
import SignUpPage from "./components/Signup";
import { useSession } from "./hook/useSession";
import Footer from "./components/component/Footer";
import AdBanner from "./components/AdScripts/AdBanner";
import "./css/index.css";

function App() {
  const location = useLocation(); // No error here because Router is wrapping this 
  const isDashboardPage = location.pathname.startsWith("/dashboard");
  const isLogn = location.pathname.startsWith("/login");
  const isSiginUp = location.pathname.startsWith("/sign-up");
  const [showSearch, setShowSearch] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [showPop, setShowPop] = useState(false);
  const [searchQuery, setSearchQuery] = useState(""); // Search query state
  const [searchResults, setSearchResults] = useState([]);
  const { isAuthenticated,Islogin } = useSession();
  const {loading} = useContext(AuthContext);
  const navigate = useNavigate();
  // Update search query on input change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    if (searchQuery.trim()) {
      setShowSearch(false);
      navigate(`/search/${encodeURIComponent(searchQuery)}`);
    }
  };

  // Update `isMobile` based on screen width
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Mobile if width <= 768px
    };
    handleResize(); // Initial check
    window.addEventListener("resize", handleResize); // Listen for resize events
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="App">
      <VideoSliderAds/>
      <YandexMetrika />
      {!isDashboardPage && (
        <div>
          <header>
            <div className="logo-and-search">
              <Link to="/">
                <img src="/bdshub.png"></img>
              </Link>
              {!isMobile ? (
                <div className="searchbar">
                  <form onSubmit={handleSearchSubmit}>
                    <input
                      type="search"
                      placeholder="Search here"
                      value={searchQuery}
                      className="header-search-input"
                      onChange={handleSearchChange}
                    />
                  </form>
                </div>
              ) : (
                <button
                  className="search-icon"
                  onClick={() => setShowSearch(true)}
                >
                  <FontAwesomeIcon icon={faSearch} />
                </button>
              )}
            </div>
            <div className="join">
              <FontAwesomeIcon
                icon={faUser}
                onClick={() => setShowPop(!showPop)}
                id="User"
              />
              <div
                className="joinBtn"
                style={{ display: showPop ? "block" : "none" }}
              >
                <ul>
                  <li onClick={() => setShowPop(false)}>
                    <Link to="/login">
                      <FontAwesomeIcon icon={faUser} />
                      Login
                    </Link>
                  </li>
                  <li onClick={() => setShowPop(false)}>
                    <Link to="/sign-up">
                      <FontAwesomeIcon icon={faUserPlus} />
                      Sign Up
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            {isMobile && showSearch && (
              <div className="search-popup">
                <div className="popup-content">
                  <form onSubmit={handleSearchSubmit}>
                    <input
                      type="search"
                      placeholder="Search here"
                      value={searchQuery}
                      className="header-search-input"
                      onChange={handleSearchChange}
                    />
                  </form>
                  <button onClick={() => setShowSearch(false)}>Cancel</button>
                </div>
              </div>
            )}
          </header>
          {!isLogn && !isSiginUp && (
            <div className="home-page-header">
              <HeaderMenu />
              <ImageSlider />
            </div>
          )}
        </div>
      )}
      {searchQuery && !isMobile && (
        <div className="search-results">
          {loading ? (
            <p>Loading...</p>
          ) : (
            searchResults.map((result) => (
              <div key={result.id}>
                <h3>{result.title}</h3>
                <p>{result.description}</p>
              </div>
            ))
          )}
        </div>
      )}
      <Routes>
        {/* Redirect to /login if the user is not logged in */}
        <Route
          path="/dashboard/*"
          element={
           isAuthenticated || Islogin ? (
              <Dashboard /> // Show Dashboard if user is logged in
            ) : (
              <Navigate to="/login" /> // Redirect to login if user is not logged in
            )
          }
        />
        <Route path="/" element={<Home />} />
        <Route path="/videos/:topic?/:page?" element={<Videos />} />
        <Route path="/view/:slug" element={<ImagesView />} />
        <Route path="/watch/:slug" element={<VideoPlayer />} />
        <Route path="/images/:topic?/:page?" element={<Images />} />

        {/* Show the login page only if the user is not logged in */}
        <Route
          path="/login"
          element={
            isAuthenticated || Islogin ? (
              <Navigate to="/dashboard" /> // Redirect to Dashboard if the user is logged in
            ) : (
              <SignInPage /> // Show login page if the user is not logged in
            )
          }
        />

        <Route path="/search/:query/:page?" element={<Search />} />
        <Route path="/sign-up" element={<SignUpPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
