import React, { useEffect, useContext, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Card from "./component/Card";
import Tags from "./component/Tags";
import { PostContext } from "../hook/postContext";
import AddSchema from "./Schema/AddSchema";
import VideoSliderAds from "./AdScripts/VideoSliderAds";
import Loader from "./component/LoadingPage";

function Home() {
  const { tags } = useContext(PostContext); // Assuming tags are provided via PostContext
  const [imagePosts, setImagePosts] = useState([]);
  const [videoPosts, setVideoPosts] = useState([]);
  const [bestPosts, setBestPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const isFetched = useRef(false); // Prevent duplicate requests

  useEffect(() => {
    if (isFetched.current) return; // Avoid duplicate API calls
    isFetched.current = true;

    const fetchPosts = async () => {
      try {
        const response = await fetch("https://api.skipthegames.tech/get-home", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setImagePosts(data.recentImagePosts || []);
        setVideoPosts(data.recentVideoPosts || []);
        setBestPosts(data.bestPosts || []);
      } catch (err) {
        console.error("Error fetching posts:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  const HomeSchema = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "name": "BDSHUB",
    "url": "https://skipthegames.tech/",
    "description": "Join our community for exclusive adult videos and content that caters to your desires. Experience pleasure like never before!",
    "publisher": {
      "@type": "Organization",
      "name": "BDSHUB - MMS Videos",
      "url": "https://skipthegames.tech/",
      "logo": {
        "@type": "ImageObject",
        "url": "https://skipthegames.tech/bdshub.png",
      },
    },
    "inLanguage": "en",
    "keywords": tags || [],
  };

  const renderPosts = (posts, loading) => {
    if (loading) return <Loader />;
    if (posts.length === 0) return <p>No posts available.</p>;
    return posts.map((post, key) => <Card key={key} post={post} />);
  };

  return (
    <div>
      <VideoSliderAds />
      <AddSchema data={HomeSchema} post={bestPosts[0] || {}} />
      <div className="home-page-container">
        {/* Popular Tags */}
        <div className="content-item" id="ForTag">
          <div className="content-head">
            <strong>Popular Tags</strong>
          </div>
          <Tags tags={tags || []} />
        </div>

        {/* Recent Video Posts */}
        <div className="content-item">
          <div className="content-head">
            <h3>Most Recent Videos</h3>
            <div className="tooltip">
              <Link to="/videos">See More</Link>
              <span className="tooltiptext">Watch All Videos</span>
            </div>
          </div>
          <div className="left">
            <div className="row" id="HomeRow">
              {renderPosts(imagePosts, loading)}
              {renderPosts(videoPosts, loading)}
            </div>
          </div>
        </div>
        {/* Best Posts */}
        <div className="content-item">
          <div className="content-head">
            <h3>Top Trending Posts</h3>
          </div>
          <div className="home-row">
            <div className="row" id="HomeRow">
              {renderPosts(bestPosts, loading)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
