import React, { useState, useEffect, useContext, useCallback } from "react";
import { useParams, NavLink, useNavigate, Link } from "react-router-dom";
import Card from "./component/Card";
import { PostContext } from "../hook/postContext";
import AddSchema from "./Schema/AddSchema";
import Loader from "./component/LoadingPage";
import axios from "axios";

function Videos() {
    const [loading, setLoading] = useState(false); // State to track loading status
    const { topic = "desi-new-videos", page = 1 } = useParams(); // Extract topic and page from URL 
    const { error, setError, setVideoPagination, videoPagination } = useContext(PostContext); // Access context
    const { totalPages, currentPage } = videoPagination;
    const [videoPosts, setVideoPosts] = useState([]); // State to store video posts
    const navigate = useNavigate(); // For navigation
    const [data, setData] = useState({}); // Initialize data as an empty object

    const fetchVideoPosts = useCallback(async (topic, page) => {
        setError(null);
        setLoading(true);
        try {
            const response = await axios.post(
                "https://api.skipthegames.tech/all-video-posts", // API endpoint for video posts
                {
                    topic,
                    page: Number(page), // Ensure the page is a number node server.js
                }
            );
            if (response.status === 200) {
                const data = response.data;
                setVideoPosts(data.posts || []); // Update videoPosts state
                setVideoPagination({
                    ...videoPagination,
                    totalPages: data.pagination.totalPages || 1,
                    currentPage: page,
                    totalPosts: data.pagination.totalPosts || 0,
                });
                setLoading(false);
            } else {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
        } catch (err) {
            setError(err.message);
        }
    }, [setVideoPagination, setError]);

    useEffect(() => {
        fetchVideoPosts(topic, page); // Fetch posts using PostContext
    }, [topic, page, fetchVideoPosts]);

    const handleTopicChange = (newTopic) => {
        navigate(`/videos/${newTopic}/1`); // Navigate to the new topic, reset to page 1
    };

    // Handling case where there might be fewer than 3 posts
    const limitedvideoPosts = videoPosts.slice(0, 3);

    // Schema Data for SEO
    const IdJson = {
        "@context": "https://schema.org",
        "@type": "ItemList",
        "name": data.title || "Desi new videos hd / sd",
        "description": data.description || "Here, you can download brand-new desi (single, duo, or couple), desi porn, homemade, video calls, selfies, amateur, hardcore, and softcore videos.",
        "url": window.location.href,
        "itemListElement": limitedvideoPosts.map((post, index) => ({
            "@type": "ListItem",
            "position": index + 1,
            "url": "https://skipthegames.tech/watch/" + post.slug,
            "name": post.title || "Default Title",
            "image": "https://skipthegames.tech" + (post.image || "default-image.jpg"),
        })),
        "numberOfItems": limitedvideoPosts.length,
        "publisher": {
            "@type": "Organization",
            "name": "BDSHUB - MMS",
            "logo": {
                "@type": "ImageObject",
                "url": "https://skipthegames.tech/images-src/bdshub.png"
            }
        }
    };

    if (loading) {
        return <Loader />;
    }

    if (error) {
        return <Loader />;
    }

    return (
        <div>
            <AddSchema data={IdJson} post={limitedvideoPosts[0]} />
            <div className="main-contentner">
                <aside className="video-topic">
                    <div className="content-head">
                        <h3>All Popular Topics</h3>
                    </div>
                    <ul>
                        {/* Replace hardcoded links with topic navigation */}
                        <li>
                            <button onClick={() => handleTopicChange("desi-new-videos")}>
                                Desi new videos hd / sd
                            </button>
                        </li>
                        <li>
                            <button onClick={() => handleTopicChange("desi-old-videos")}>
                                Desi Old Videos HD / SD
                            </button>
                        </li>
                        <li>
                            <button onClick={() => handleTopicChange("desi-model-webcam-girls")}>
                                Desi model | Webcam Girls
                            </button>
                        </li>
                        <li>
                            <button onClick={() => handleTopicChange("live-streams-instragram-onlyfans")}>
                                Live Streams | Instragram | OnlyFans
                            </button>
                        </li>
                        <li>
                            <button
                                onClick={() =>
                                    handleTopicChange("live-streams-instragram-onlyfans")
                                }
                            >
                                Live Streams | Instragram | OnlyFans
                            </button>
                        </li>
                        <li>
                            <button
                                onClick={() => handleTopicChange("world-pronster-videos")}
                            >
                                World Pronster Videos
                            </button>
                        </li>
                    </ul>
                </aside>
                <div className="row">
                    {/* Map through posts and render a PostCard for each */}
                    {videoPosts.length > 0 ? (
                        videoPosts.map((post) => <Card key={post.id} post={post} />)
                    ) : (
                        <div id="VIdeoNoResilt">
                            <p>No posts available.</p>
                        </div>
                    )}
                </div>
            </div>
            {videoPagination.totalPages > 1 && (<div className="pagination">
                <ul>
                    <NavLink to={`/videos/${topic}/${Math.max(1, currentPage - 1)}`}>
                        <li className={currentPage === 1 ? "disabled" : ""}>&laquo;</li>
                    </NavLink>

                    {/* Show a max of 5 page links */}
                    {Array.from({ length: 5 }, (_, i) => {
                        // Calculate the page number dynamically
                        const pageNumber = currentPage + i - 2; // 2 previous, current, 2 next
                        if (pageNumber >= 1 && pageNumber <= totalPages) {
                            return (
                                <NavLink to={`/videos/${topic}/${pageNumber}`} key={pageNumber}>
                                    <li className={currentPage === pageNumber ? "ActiveP" : ""}>
                                        {pageNumber}
                                    </li>
                                </NavLink>
                            );
                        }
                        return null; // Skip pages that are out of range
                    })}

                    <NavLink to={`/videos/${topic}/${Math.min(totalPages, currentPage + 1)}`}>
                        <li className={currentPage === totalPages ? "disabled" : ""}>&raquo;</li>
                    </NavLink>
                </ul>
            </div>
            )}

        </div>
    );
}

export default Videos;
