import React from "react";
import { Link } from "react-router-dom";

const DashboardHome = () => {
  return (
    <section id="home" className="section">
      <h2>Welcome To Bdshub Dashboard</h2>
        <p className="not-found-message">
        Create a new post and share your thoughts with the world.
        </p>
        <Link to="/dashboard/create-post" className="btn btn-primary">
        <button className="btn btn-primary">Create New Post</button>
        </Link>
      </section>
  );
};

export default DashboardHome;
