import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

// Create a context for authentication
export const AuthContext = createContext();

// AuthContext Provider component
export const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(null); // State for storing the JWT token
  const [user, setUser] = useState({}); // State for user details
  const [Islogin, setIslogin] = useState(sessionStorage.getItem("Islogin") === true); // State for login status

  // Check for stored token and user details on initial load
  useEffect(() => {
    const storedToken = localStorage.getItem("authToken") || sessionStorage.getItem("authToken");
    if (storedToken) {
      setAuthToken(storedToken);
      fetchUserDetails(storedToken); // Fetch user details from the server
    }
  }, []);

  // Store user data in localStorage whenever it changes
  useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      localStorage.setItem("user", JSON.stringify(user));
    }
  }, [user]);

  // Function to fetch user details using the token
  const fetchUserDetails = async (token) => {
    try {
      const response = await axios.post(
        "https://api.skipthegames.tech/auth/user",
        {}, // No body required, just the token in headers
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setUser(response.data.user || JSON.parse(localStorage.getItem("user")));
    } catch (error) {
      console.error("Failed to fetch user details:", error.response?.data || error.message);
      logout(); // Clear token if invalid
    }
  };

  // Function to log in the user
  const login = async (email, password) => {
    try {
      const response = await axios.post("https://api.skipthegames.tech/auth/login", { email, password });
      const token = response.data.token;

      setAuthToken(token);
      localStorage.setItem("authToken", token); // Store only the token
      sessionStorage.setItem("Islogin", true); // Store login status
      fetchUserDetails(token);
    } catch (error) {
      console.error("Login failed:", error.response?.data || error.message);
      throw error;
    }
  };

  // Function to log out the user
  const logout = async () => {
    try {
      await axios.post("https://api.skipthegames.tech/auth/logout", null, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
    } catch (error) {
      console.error("Logout failed:", error.response?.data || error.message);
    } finally {
      // Clear local data regardless of the logout result
      setAuthToken(null);
      setUser({});
      sessionStorage.setItem("Islogin", false); // Store login status
      localStorage.removeItem("authToken");
      localStorage.removeItem("user");
    }
  };

  return (
    <AuthContext.Provider value={{ user, authToken, login, logout, fetchUserDetails, Islogin }}>
      {children}
    </AuthContext.Provider>
  );
};
