import React, { useState } from "react";
import { NavLink ,Link} from "react-router-dom";
function HeaderMenu() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="header-menu" style={{ position: "relative" }}>
      <div className="NavLink">
        <NavLink to="/">Home</NavLink>
        <NavLink to="/videos">Watch Videos</NavLink>
        <NavLink to="/images">Viral MMS</NavLink>
        <NavLink to="/search/pronster">Pronster</NavLink>
        {/* Categories with dropdown */}
        <div
          style={{
            display: "inline-block",
            position: "relative",
          }}
          onMouseEnter={() => setIsDropdownOpen(true)} // Show dropdown on hover
          onMouseLeave={() => setIsDropdownOpen(false)} // Hide dropdown on mouse leave
        >
          {/* <Link
            to="#"
            onClick={(e) => {
              e.preventDefault(); // Prevent NavLink navigation
              toggleDropdown(); // Toggle dropdown on click
            }}
          >
            Categories
          </Link> */}
          {/* Dropdown Menu */}
          {/* {isDropdownOpen && (
            <div className="dropdown-menu">
              {Array.from({ length: 12 }).map((_, index) => (
                <NavLink
                  key={index}
                  to={`/categories/${index + 1}`}
                >
                  Category {index + 1}
                </NavLink>
              ))}
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
}

export default HeaderMenu;

