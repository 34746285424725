import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";


function AddSchema({ data, post = {} }) {
    const default_description =
        "Join our community for exclusive adult videos and content that caters to your desires. Experience pleasure like never before!";
    const default_title =
        "Bdshub Viral Video: Download Exclusive desi original videos, photos without watermark on our Website.";
    const default_image = "/default-image.png";

    if (!data || !post) {
        console.warn("AddSchema: Missing `data` or `post` props.");
        return null; // Do not render anything if critical props are missing
    }

    return (
        <Helmet>
            {/* Dynamic Page Title and Meta Tags */}
            <title>{post.title || default_title}</title>
            <meta name="yandex-verification" content="edc7f32afb23596f" />
            <meta name="robots" content="index, follow" />
            <meta name="googlebot" content="index, follow" />
            <meta name="bingbot" content="index, follow" />
            <meta name="author" content="bdshub" />
            <link rel="canonical" href={window.location.href} />
            <meta property="og:type" content="video.other" />
            <link rel="dns-prefetch" href="https://skipthegames.tech" />

            {/* <meta
        property="og:video"
        content={
          `${window.location.href}post_videos/${video.video || ""}`
        }
      />
      <meta property="og:video:width" content="1280" />
      <meta property="og:video:height" content="720" />
      <meta property="og:video:type" content="video/mp4" /> */}
            <meta
                name="description"
                content={post.description || default_description}
            />
            <meta property="og:title" content={post.title || default_title} />
            <meta
                property="og:description"
                content={post.description || default_description}
            />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:image" content={"https://api.skipthegames.tech/post_images/" + post.image || default_image} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={post.title || default_title} />
            <meta
                name="twitter:description"
                content={post.description || default_description}
            />
            <meta name="twitter:image" content={"https://api.skipthegames.tech/post_images/" + post.image || default_image} />

            {/* Structured Data */}
            <script type="application/ld+json">{JSON.stringify(data)}</script>

        </Helmet>
    );
}

AddSchema.propTypes = {
    data: PropTypes.object.isRequired,
    post: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        image: PropTypes.string,
    }),
};

export default AddSchema;
