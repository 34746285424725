import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import RecommendedCard from "./component/RecommendedCard";
import Card from "./component/Card";
import FollowButton from "./component/FollowButton";
import VideoReactions from "./component/VideoReactions";
import AdScriptLoader from "./AdScripts/AdScriptLoader";
import DownloadLinks from "./component/DownloadLinks";
import AdScriptLoaderAdsTera from "./AdScripts/ AdScriptLoaderAdsTera";
import Loader from "./component/LoadingPage";
import AddSchema from "./Schema/AddSchema";
function ImagesView() {
    const { slug } = useParams(); // Get video slug from the URL
    const [posts, setPosts] = useState({});
    const [rPosts, setRPosts] = useState([]);
    const [reletedpost, setReletedpost] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const timeAgo = (dateString) => {
        if (!dateString) return "Unknown date";
        const date = new Date(dateString);
        const now = new Date();
        const secondsAgo = Math.floor((now - date) / 1000);

        const intervals = [
            { label: "year", seconds: 31536000 },
            { label: "month", seconds: 2592000 },
            { label: "week", seconds: 604800 },
            { label: "day", seconds: 86400 },
            { label: "hour", seconds: 3600 },
            { label: "minute", seconds: 60 },
            { label: "second", seconds: 1 },
        ];

        for (let i = 0; i < intervals.length; i++) {
            const interval = intervals[i];
            const count = Math.floor(secondsAgo / interval.seconds);
            if (count > 0) {
                return `${count} ${interval.label}${count !== 1 ? "s" : ""} ago`;
            }
        }
        return "just now";
    };
    const scriptKeys = "//yellowprotection.com/bXXCV/sLd.GAlv0sYQWOdHirYJWO5guBZ/XyIf/xe/mx9huXZsUZlxkPP/TjYfwPM/zOcN3ON/zlgKt/NHjMAvzaN_zrcI3fOVQK";
    const scriptSrc1 = "https://private-hide.com/bwX.VosgdMGJlq0NYyW/d/ieY/W/5Zu/ZgX/IS/beJmM9xuHZEUUl/k/PKT/Uw2OOLTmAX4fMRDXgIt/NMTmYx5wMmD_gAwpOjQK";
    const scriptSrc2 = "https://private-hide.com/b.XnVcsIdvG_ly0YYZWbdDiLYQWV5huYZ-X/IX/KevmH9VuCZvUclwkVPRTBUb3jMrTPcOz/MWDzEFtvN/THcBxvN_zLMtwlMXgj";
    const fetchPostData = async () => {
        setLoading(true); // Start loading
        try {
            const response = await fetch(
                "https://api.skipthegames.tech/images-posts-view",
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ slug }), // Pass the slug as payload
                }
            );

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            } else {
                setLoading(false); // Stop loading
            }

            const data = await response.json();
            setPosts(data.Posts || {});
            setReletedpost(data.relatedPosts || []);
            setRPosts(data.similarPosts || []);
        } catch (error) {
            console.error("Error fetching data:", error);
            setError(error.message); // Set error state
        } finally {
            setLoading(false); // Stop loading
        }
    };

    useEffect(() => {
        fetchPostData();
    }, [slug]);
    const {
        title,
        view_count,
        created_at,
        profile_image,
        username,
        id,
        user_id,
        description,
        image
    } = posts;

    const reaction = {
        likes: posts.likes || 0,
        dislikes: posts.dislikes || 0,
        favorites: posts.favorites || 0,
        id,
        type: "image"
    };
    const adConfig = {
        scriptKey: "24139efd90194860bcfe82b51fee9054",
        scriptSrc: "//conductorhimselfwhipped.com/24139efd90194860bcfe82b51fee9054/invoke.js",
        height: 250,
        width: 300,
    };
    const IdJson = {
        "@context": "https://schema.org",
        "@type": "ItemList",
        "name": title || "Desi new videos hd / sd",
        "description": description || "Here, you can download brand-new desi (single, duo, or couple), desi porn, homemade, video calls, selfies, amateur, hardcore, and softcore videos.",
        "url": window.location.href,
        "publisher": {
          "@type": "Organization",
          "name": "BDSHUB - MMS",
          "logo": {
            "@type": "ImageObject",
            "url": "https://skipthegames.tech/images-src/bdshub.png"
          }
        }
      };

    if (loading) {
        return <Loader />;
    }

    if (error) {
        return <Loader />;
    }



    return (
        <div>
            <AddSchema data={IdJson} post={posts}/>
            <div className="page-header"></div>
            <div className="main-content-container" id="main-content-containerIimage">
                {/* Left Content */}
                <div className="main-content-left" id="image-content-left">
                    <div className="profile-follow" id="profile-div">
                        <div className="user-profile">
                            <div className="user-profile-logo">
                                <img
                                    src={"https://api.skipthegames.tech/user_images/"+ profile_image || "/images-src/user.png"}
                                    alt="Profile"
                                />
                            </div>
                            <div className="user-profile-text">
                                <span id="username">{username || "Unknown User"}</span>
                                <small>{timeAgo(created_at)}</small>
                            </div>
                        </div>
                        <div className="user-follow">
                            <FollowButton
                                initialFollowing={true}
                                onFollowChange={(isFollowing) =>
                                    console.log("Follow state:", isFollowing)
                                }
                            />
                        </div>
                    </div>
                    <div id="content">
                        <h1 id="title">{title || "Untitled"}</h1>
                        <div id="description">
                            <div dangerouslySetInnerHTML={{ __html: description }}></div>
                        </div>
                        <div id="Image">
                            <img src={"https://api.skipthegames.tech/post_images/"+image ||"/images-src/default-poster.png"} alt="Image" />
                        </div>
                        <div id="Rightvideo" className="AdvertisementIx">
                        <AdScriptLoader scriptSrc={scriptKeys} containerId="ad-containerx" />
                        </div>
                        <DownloadLinks posts={posts} />
                    </div>
                    <div className="video-footer" id="ImagesViewfooter">
                        <div className="commant">
                            <button onClick={() => console.log("Comment button clicked")}>
                                Comment
                            </button>
                        </div>
                        <VideoReactions reaction={reaction} />
                    </div>
                </div>

                {/* Right Side Content */}
                <div className="main-content-right">
                    <span>Advertisement</span>
                    <div id="Rightvideo" className="AdvertisementIx">
                        <AdScriptLoader scriptSrc={scriptSrc1} containerId="ad-container1" />
                        <AdScriptLoader scriptSrc={scriptSrc2} containerId="ad-container2" />
                    </div>
                    <span>Recommended Posts</span>
                    <div id="Rightvideo">
                        <RecommendedCard posts={rPosts} />
                    </div>
                </div>
            </div>

            {/* Related Posts */}
            <div className="title-div">
                <span>Related Posts</span>
            </div>
            <div className="main-contentner">
                <div className="row" id="ImageViewRow">
                    {reletedpost && reletedpost.length > 0 ? (
                        reletedpost.map((post, index) => <Card key={index} post={post} />)
                    ) : (
                        <p>No posts available</p>
                    )}
                </div>
                <div id="videoAds">
                    <AdScriptLoaderAdsTera
                        scriptKey={adConfig.scriptKey}
                        scriptSrc={adConfig.scriptSrc}
                        height={adConfig.height}
                        width={adConfig.width}
                        containerId="Ads01"
                    />
                    <AdScriptLoaderAdsTera
                        scriptKey={adConfig.scriptKey}
                        scriptSrc={adConfig.scriptSrc}
                        height={adConfig.height}
                        width={adConfig.width}
                        containerId="Ads02"
                    />
                    <AdScriptLoaderAdsTera
                        scriptKey={adConfig.scriptKey}
                        scriptSrc={adConfig.scriptSrc}
                        height={adConfig.height}
                        width={adConfig.width}
                        containerId="Ads03"
                    />
                </div>
            </div>
        </div>
    );
}

export default ImagesView;
 