import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

const ImageSlider = () => {
  const [adsdata, setAdsData] = useState({ adsimages: [], adslinks: [] });
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    // Fetch ads data from the server
    const fetchAdsData = async () => {
      try {
        const response = await fetch('https://api.skipthegames.tech/adsdata'); // API to fetch ads data
        const data = await response.json();
        setAdsData(data);
        // Initialize with a random index
        setCurrentIndex(Math.floor(Math.random() * data.adsimages.length));
      } catch (error) {
        console.error("Error loading ads data:", error);
      }
    };

    fetchAdsData();
  }, []);

  // Function to randomly change the slide
  const changeSlideRandomly = () => {
    if (adsdata.adsimages.length > 1) {
      let newIndex;
      do {
        newIndex = Math.floor(Math.random() * adsdata.adsimages.length);
      } while (newIndex === currentIndex); // Avoid showing the same image consecutively
      setCurrentIndex(newIndex);
    }
  };

  // Auto-change the slide every 3 seconds
  useEffect(() => {
    const interval = setInterval(changeSlideRandomly, 5000); // Change every 3 seconds
    return () => clearInterval(interval); // Clear interval on component unmount
  }, [currentIndex, adsdata.adsimages]);

  return (
    <div style={{ position: 'relative' }} className="image-slider">
      {adsdata.adsimages.length > 0 && (
        <Link to={adsdata.adslinks[currentIndex]} target="_blank" rel="noopener noreferrer">
          <img
            src={adsdata.adsimages[currentIndex]} // Image URL
            alt={`Ad ${currentIndex}`}
            style={{
              width: '100%',
              borderRadius: '10px',
              transition: 'opacity 1s ease-in-out',
            }}
          />
        </Link>
      )}
    </div>
  );
};

export default ImageSlider;
