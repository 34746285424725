import React, { useEffect, useState, useContext } from "react";
import { Routes, Route, Link } from "react-router-dom";
import AddOrEditPost from "./AddOrEditPost";
import ManagePosts from "./ManagePosts";

import SignOut from "../component/SignOut";
import Admin from "./Admin";
import SettingsPage from "./SettingsPage";
import { AuthContext } from "../../hook/authContext";
import ProtectedRoute from "./ProtectedRoute";
import VideoPosts from "./VideoPosts";
import DashboardHome from "./DashboardHome";
import {
  faSquarePlus,
  faRightToBracket,
  faLayerGroup,
  faHouse,
  faUser,
  faLock,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Dashboard = () => {
  const [isMobile, setIsMobile] = useState(false); // Mobile check
  const [showPop, setShowPop] = useState(false); // Sidebar toggle
  const { user} = useContext(AuthContext);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 570); // Mobile if width <= 570px
      if (window.innerWidth > 570) {
        setShowPop(true); // Always show sidebar on larger screens
      }
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize); // Listen for resize events
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <header className="dashboard-header">
        <div className="logo">
          {isMobile && (
            <FontAwesomeIcon
              onClick={() => setShowPop(!showPop)}
              icon={faBars}
              className="menu-icon"
            />
          )}
          <Link to="/">
            <img src="/bdshub.png"></img>
          </Link>
        </div>
        <div className="user-menu">
          <FontAwesomeIcon icon={faUser} />
        </div>
      </header>

      <div className="dashboard">
        {/* Sidebar */}
        <div className={`dashboard-left ${showPop ? "show" : "hide"}`}>
          <nav onClick={() => isMobile && setShowPop(false)}>
            <ul>
              <li>
                <Link to="/dashboard">
                  <FontAwesomeIcon icon={faHouse} /> Home
                </Link>
              </li>
              <li>
                <Link to="/dashboard/create-post">
                  <FontAwesomeIcon icon={faSquarePlus} /> Create Post
                </Link>
              </li>
              <li>
                <Link to="/dashboard/video-post">
                  <FontAwesomeIcon icon={faSquarePlus} /> Video Post
                </Link>
              </li>
              <li>
                <Link to="/dashboard/manage-post">
                  <FontAwesomeIcon icon={faLayerGroup} /> Manage Posts
                </Link>
              </li>
              <li>
                <Link to="/dashboard/admin">
                  <FontAwesomeIcon icon={faLock} /> Admin
                </Link>
              </li>
              <li>
                <Link to="/dashboard/settings">
                  <FontAwesomeIcon icon={faUser} /> Settings
                </Link>
              </li>
              <li>
                <Link to="/dashboard/signout">
                  <FontAwesomeIcon icon={faRightToBracket} /> Sign Out
                </Link>
              </li>
            </ul>
          </nav>
        </div>

        {/* Content Area */}
        <div className="dashboard-right">
          <Routes>
            <Route path="/" element={<DashboardHome />} />
            <Route path="create-post" element={<AddOrEditPost />} />
            <Route path="manage-post/:page?" element={<ManagePosts />} />
            <Route
              path="admin"
              element={
                <ProtectedRoute
                  isAllowed={user?.role === "admin"} // Check if the user is an admin
                  redirectTo="/"
                >
                  <Admin />
                </ProtectedRoute>
              }
            />video-post
            <Route path="settings" element={<SettingsPage user={user} />} />
            <Route path="video-post" element={<VideoPosts />} />
            <Route path="signout" element={<SignOut />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
