import React, { useState, useEffect } from "react";
import { useParams, NavLink } from "react-router-dom";
import Card from "./Card";
import Loader from "./LoadingPage";
function Search() {
  const { query, page = "1" } = useParams(); // Extract query and page from URL
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [Pagination, setPagination] = useState({ totalPages: 1, currentPage: 1, totalPosts: 0 });
  // Convert `page` to a number for calculations
  const currentPage = Number(page);

  // Fetch search results when the query or page changes
  useEffect(() => {
    if (query) {
      fetchSearchResults(query, currentPage);
    }
  }, [query, currentPage]);

  // Fetch search results from API
  const fetchSearchResults = async (searchQuery, page) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch("https://api.skipthegames.tech/search-post", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ query: searchQuery, page, limit: 20 }), // Pass page and limit
      });

      if (!response.ok) {
        throw new Error("No posts found for the given topic.");
      }

      const data = await response.json();
      setResults(data.results || []);
      setPagination({
        totalPages: data.pagination.totalPages || 1,
        currentPage: data.pagination.currentPage || 1,
        totalPosts: data.pagination.totalPosts || 0,
      });
      if (data.results.length === 0) {
        throw new Error("No results found.");
      }
    } catch (error) {
      setError(error.message || "Error fetching posts.");
      setResults([]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="searchDiv">
      <div className="main-container">
        <h3 className="show-search">Search Results for : "{query}"</h3>
        <div className="search-results">
          {loading ? (
            <Loader />
          ) : error ? (
            <div className="no-results">
              <p>No results found.</p>
            </div>
          ) : results.length > 0 ? (
            results.map((result) => <Card post={result} key={result.id} />)
          ) : (
            <div className="no-results">
              <p>No post found.</p>
            </div>
          )}
        </div>
      </div>

      {/* Pagination */}
      {Pagination.totalPages > 1 && (
              <div className="pagination">
                <ul>
                  {/* Previous Page */}
                  <NavLink
                    to={`/search/${query}/${Math.max(1, Pagination.currentPage - 1)}`}
                  >
                    <li className={Pagination.currentPage === 1 ? "disabled" : ""}>&laquo;</li>
                  </NavLink>
      
                  {/* Page Numbers */}
                  {Array.from({ length: Math.min(5, Pagination.totalPages) }, (_, i) => {
                    const currentPage = Pagination.currentPage;
                    const startPage = Math.max(1, currentPage - 2);
                    const pageNumber = startPage + i;
      
                    return (
                      <NavLink to={`/search/${query}/${pageNumber}`} key={pageNumber}>
                        <li className={currentPage === pageNumber ? "ActiveP" : ""}>{pageNumber}</li>
                      </NavLink>
                    );
                  })}
      
                  {/* Next Page */}
                  <NavLink
                    to={`/search/${query}/${Math.min(Pagination.totalPages, Pagination.currentPage + 1)}`}
                  >
                    <li className={Pagination.currentPage === Pagination.totalPages ? "disabled" : ""}>&raquo;</li>
                  </NavLink>
                </ul>
              </div>
            )}

    </div>
  );
}

export default Search;
