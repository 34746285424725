import { useContext } from "react";
import { AuthContext } from "./authContext";
export const useSession = () => {
  const { user, authToken, Islogin } = useContext(AuthContext);

  return {
    user,         // Current user details
    authToken,    // Authentication token
    isAuthenticated: !!authToken, // Boolean indicating if the user is logged in
    Islogin,      // Additional login state
  };
};
