import React, { useContext, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { SignInPage, AppProvider } from '@toolpad/core';
import { useTheme, ThemeProvider, createTheme } from '@mui/material/styles';
import { Alert, Snackbar, Backdrop, CircularProgress } from '@mui/material'; // Added Backdrop and CircularProgress
import { AuthContext } from "../hook/authContext";

// Create a custom theme
const customTheme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
    background: {
      default: '#1b1b1b',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h1: {
      fontSize: '2.5rem',
    },
  },
});

const providers = [{ id: 'credentials', name: 'Email and Password' }];

// Define the signIn function
const signIn = async (email, password, login, setMessage, navigate, setError, setLoading) => {
  try {
    setLoading(true); // Start loading
    await login(email, password); // Attempt to login via context
    setMessage({ type: 'success', text: `Welcome, ${email}!` }); // Show success message
    setTimeout(() => {
      setLoading(false); // Stop loading
      navigate('/dashboard'); // Redirect to the dashboard or home page after successful login  
    }, 2000);
  } catch (err) {
    setLoading(false); // Stop loading on error
    setError("Invalid credentials, please try again.");
    setMessage({ type: 'error', text: "Login failed. Please check your credentials." }); // Error message
  }
};

export default function CredentialsSignInPage() {
  const theme = useTheme();
  const [message, setMessage] = useState(null); // State to hold messages
  const [error, setError] = useState(null); // State to hold error message
  const [loading, setLoading] = useState(false); // State to hold loading status
  const { login } = useContext(AuthContext); // Access login function from AuthContext
  const navigate = useNavigate(); // Correctly use navigate inside the component

  return (
    <ThemeProvider theme={customTheme}>
      <AppProvider theme={theme}>
        <SignInPage
          signIn={(provider, formData) => {
            const email = formData.get('email'); // Retrieve email
            const password = formData.get('password'); // Retrieve password
            signIn(email, password, login, setMessage, navigate, setError, setLoading); // Call signIn function
          }}
          providers={providers}
        />
        
        {/* Loading Spinner */}
        <Backdrop sx={{ color: '#fff', zIndex: theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
          <span style={{ marginLeft: '10px' }}>Please wait...</span>
        </Backdrop>
        
        {message && (
          <Snackbar
            open={Boolean(message)}
            autoHideDuration={6000}
            onClose={() => setMessage(null)} // Clear message after 6 seconds
          >
            <Alert
              onClose={() => setMessage(null)}
              severity={message.type}
              sx={{ width: '100%' }}
            >
              {message.text}
            </Alert>
          </Snackbar>
        )}
        
        {error && (
          <Snackbar
            open={Boolean(error)}
            autoHideDuration={6000}
            onClose={() => setError(null)} // Clear error message after 6 seconds
          >
            <Alert
              onClose={() => setError(null)}
              severity="error"
              sx={{ width: '100%' }}
            >
              {error}
            </Alert>
          </Snackbar>
        )}
      </AppProvider>
    </ThemeProvider>
  );
}
