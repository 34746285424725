import {React,useContext} from 'react';
import { AuthContext } from '../../hook/authContext';
function Logout() {
  const { logout } = useContext(AuthContext);
  const handleLogout = () => {
    logout();
  };

  return (
    <section id="logout" className="section">
      <button onClick={handleLogout}>Confirm Logout</button>
    </section>
  );
}

export default Logout;
